const TravelGuideIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M7.30435 3.05549L1.46087 0.611036C1.14783 0.407332 0.730435 0.509184 0.417391 0.712889C0.208696 0.916593 0 1.22215 0 1.52771V12.7315C0 13.1389 0.208696 13.5463 0.626087 13.6481L7.30435 16.5V3.05549Z"
      fill="#444444"
    />
    <path
      d="M15.5823 0.7127C15.2692 0.508995 14.9562 0.508995 14.6431 0.610847L8.69531 3.0553V16.4998L15.3736 13.7498C15.791 13.5461 15.9997 13.2405 15.9997 12.8331V1.52752C15.9997 1.22196 15.791 0.916404 15.5823 0.7127Z"
      fill="#444444"
    />
  </svg>
);
export default TravelGuideIcon;
