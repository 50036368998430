import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import type { SwiperOptions } from 'swiper';
import COLORS from 'const/colors';

const Swiper = dynamic(() => import('components/Swiper'), {
  ssr: false,
});

const StyledSlider = styled.div`
  display: flex;
  position: relative;
  ${({
    // @ts-expect-error TS(2339): Property 'parentOverflowHidden' does not exist on ... Remove this comment to see the full error message
    parentOverflowHidden,
  }) => (parentOverflowHidden ? 'overflow: hidden;' : '')}
  .slider-bullet {
    height: 7px;
    width: 7px;
    display: block;
    border-radius: 100%;
    cursor: pointer;
    background: ${COLORS.BRAND.WHITE};
    opacity: 0.6;
  }
  .slider-bullet.swiper-pagination-bullet-active {
    opacity: 1;
    height: 10px;
    width: 10px;
  }
  .swiper-container {
    width: 100%;
    height: max-content;
  }
  .slider-pagination {
    z-index: 10;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    position: absolute;
    display: grid;
    grid-auto-flow: column;
  }
  @media (max-width: 768px) {
    height: max-content;
    .swiper-wrapper {
      height: max-content;
    }
    .swiper-container {
      width: 100%;
      height: auto;
    }
    .slider-bullet {
      height: 6px;
      width: 6px;
    }
    .slider-bullet.swiper-pagination-bullet-active {
      height: 8px;
      width: 8px;
    }
  }
`;

const Controls = styled.div`
  .prev-slide,
  .next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    cursor: pointer;
    z-index: 2;
    svg {
      fill: #fff;
      circle {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
      border-radius: 100%;
      box-shadow: path {
        stroke-width: 2px;
      }
    }
  }
  .next-slide {
    left: unset;
    right: 10px;
    svg {
      transform: rotate(180deg);
    }
  }
`;

const Slider = ({
  children,
  sliderOptions,
  nextButton,
  prevButton,
  parentOverflowHidden = false,
  paginationClass,
  onSlideChange,
}: {
  children: React.ReactNode[];
  sliderOptions?: SwiperOptions;
  nextButton?: HTMLElement | React.ReactNode | JSX.Element;
  prevButton?: HTMLElement | React.ReactNode | JSX.Element;
  parentOverflowHidden?: boolean;
  paginationClass?: string;
  onSlideChange?: () => void;
}) => {
  /* Swiper configuration for using external controls starts here */
  const [swiper, updateSwiper] = useState(null);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const goToSlide = (index: number) => {
    if (swiper !== null) {
      (swiper as any).slideTo(index);
    }
  };

  const goNext = () => {
    if (swiper !== null) {
      (swiper as any).slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      (swiper as any).slidePrev();
    }
  };

  const updateIndex = useCallback(() => {
    updateCurrentIndex((swiper as any)?.realIndex as number);
    onSlideChange?.();
  }, [swiper]);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <StyledSlider parentOverflowHidden={parentOverflowHidden}>
      <Swiper
        {...sliderOptions}
        /* @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message */
        onSwiper={updateSwiper}
        onSlideChange={updateIndex}
      >
        {children?.map((child, index) => {
          return (
            <div className="swiper-slide" key={index}>
              {child}
            </div>
          );
        })}
      </Swiper>
      {nextButton && prevButton ? (
        <Controls>
          {!(swiper as any)?.isBeginning ? (
            <div
              className="prev-slide"
              role="button"
              tabIndex={0}
              onClick={goPrev}
            >
              {prevButton}
            </div>
          ) : null}
          {!(swiper as any)?.isEnd ? (
            <div
              className="next-slide"
              role="button"
              tabIndex={0}
              onClick={goNext}
            >
              {nextButton}
            </div>
          ) : null}
        </Controls>
      ) : null}
      {paginationClass ? (
        <div
          className={`${paginationClass} slider-pagination swiper-pagination-clickable swiper-pagination-bullets`}
        >
          {children.map((_item, index) => {
            return (
              <span
                key={index}
                className={`slider-bullet ${
                  index === currentIndex
                    ? 'swiper-pagination-bullet-active'
                    : ''
                }`}
                tabIndex={0}
                role="button"
                onClick={() => {
                  goToSlide(index);
                }}
                aria-label={`Go to slide ${index}`}
              />
            );
          })}
        </div>
      ) : null}
    </StyledSlider>
  );
};

export default Slider;
